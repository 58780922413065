<div
  class="grid min-h-dvh place-items-stretch"
  [class.grid-rows-[auto_auto_1fr_auto]]="stageWarning.message"
  [class.grid-rows-[auto_1fr_auto]]="!stageWarning.message"
>
  @if (stageWarning.message) {
    <ui-stage-info class="sticky top-0 z-10" [icon]="stageWarning.icon">{{
      stageWarning.message
    }}</ui-stage-info>
  }
  <ui-header class="mb-10" [links]="links$ | async">
    <ng-container ngProjectAs="'[logo]'">
      @if (headerLogo$ | async; as headerLogo) {
        <div class="flex items-center justify-center gap-2">
          <a [routerLink]="''">
            <img
              class="h-[40px]"
              [src]="'data:image/svg+xml;base64,' + headerLogo.logo"
              [alt]="headerLogo.produkt"
            />
          </a>
          <span class="ml-4">{{ headerLogo.sparte }}</span>
        </div>
      } @else {
        <div class="flex items-center justify-center">
          <a [routerLink]="''">
            <img class="w-[126px]" src="/assets/logo.svg" alt="Company Logo" />
          </a>
        </div>
      }
    </ng-container>

    <ng-container ngProjectAs="[avatar]">
      @if (keycloakService.isLoggedIn()) {
        <comparit-avatar-user />
      }
    </ng-container>
  </ui-header>

  <div class="page-container w-full">
    <router-outlet />
  </div>

  @if (backendVersionInfo$ | async; as versionInfo) {
    <ui-footer
      class="sticky bottom-0 z-10"
      [clientVersion]="clientVersion"
      [backendVersion]="versionInfo.buildVersion"
      [backendStage]="versionInfo.stage"
    />
  }
</div>
