import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { KeycloakService } from 'keycloak-angular';
import { RouterLink } from '@angular/router';
import { adminRole } from '../../../constants/admin-role';

@Component({
  selector: 'comparit-avatar-user',
  standalone: true,
  imports: [CommonModule, MatMenuModule, MatIconModule, RouterLink],
  templateUrl: './avatar-user.component.html',
  styleUrls: ['./avatar-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarUserComponent {
  authService = inject(KeycloakService);
  user$ = this.authService.loadUserProfile();

  protected isAdmin = this.authService.isUserInRole(adminRole);

  logout() {
    window.localStorage.setItem('sparte', '');
    window.localStorage.setItem('produkt', '');
    this.authService.logout(window.location.origin);
  }
}
