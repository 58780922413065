<div class="flex justify-center items-center space-x-2">
  <img src="/assets/icons/profile.svg" class="mr-4" />
  <span class="font-bold">
    {{ (user$ | async)?.lastName }}
  </span>
  <a mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon class="align-middle" svgIcon="cp-direction-down" />
  </a>
</div>
<mat-menu #menu="matMenu">
  @if (isAdmin) {
    <a mat-menu-item routerLink="/sparte-features">Sparteneinstellungen</a>
  }
  <button mat-menu-item (click)="logout()">Abmelden</button>
</mat-menu>
