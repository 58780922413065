import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CustomIconsService,
  FooterComponent,
  HeaderComponent,
  InterfaceComponentLinksMenu,
  KeycloakAuthService,
  PromptUpdateService,
  StageInfoComponent,
  UpdateAppModalComponent,
} from '@comparit/core';
import { combineLatest, distinctUntilChanged, filter, map, share, switchMap, take } from 'rxjs';
import type { RouteString } from './routes.model';
import { AvatarUserComponent } from './page-login/avatar-user/avatar-user.component';
import { KeycloakService } from 'keycloak-angular';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { StateService } from '../services/state.service';
import { ProduktService, SparteService, TarifIngressService } from '../ti-interfaces';
import { environment } from '../environments/environment';
import { clientVersion } from '../constants/version';

const HEADER_LINKS: InterfaceComponentLinksMenu<RouteString>[] = [
  {
    url: '/insurers',
    title: 'Versicherer',
  },
  {
    url: '/tarifbereiche',
    title: 'Tarifbereiche',
  },
  {
    url: '/tariffs',
    title: 'Tarife',
  },
  {
    url: '/attributes',
    title: 'Attribute',
  },
  {
    url: '/berufelisten',
    title: 'Berufelisten',
  },
  {
    url: '/fondslisten',
    title: 'Fondslisten',
  },
  {
    url: '/konstanten',
    title: 'Konstanten',
  },
];

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    HeaderComponent,
    AvatarUserComponent,
    MatDialogModule,
    StageInfoComponent,
    FooterComponent,
  ],
  selector: 'comparit-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private _promptUpdateService = inject(PromptUpdateService);
  private _customIconsService = inject(CustomIconsService);
  private _sparte = inject(SparteService);
  private _produkt = inject(ProduktService);
  private _state = inject(StateService);
  private _matDialog = inject(MatDialog);
  private tarifIngressService = inject(TarifIngressService);

  protected backendVersionInfo$ = this.tarifIngressService.versionInfo();

  protected clientVersion = clientVersion;

  private sparte$ = this.selectSparte();

  protected stageWarning = environment.stageWarning;

  keycloakService = inject(KeycloakService);
  keycloakAuthService = inject(KeycloakAuthService);

  headerLogo$ = combineLatest([this.sparte$, this.selectProdukt()]).pipe(
    filter(([sparte, produkt]) => !!(sparte && produkt)),
    filter(([sparte, produkt]) => sparte !== null && produkt !== null),
    map(([sparte, produkt]) => ({
      sparte: sparte?.name?.value,
      produkt: produkt?.name?.value,
      logo: produkt?.logo?.value,
    }))
  );

  title = 'Tarifingress';

  links$ = this.sparte$.pipe(
    map(sparte =>
      (this.keycloakService.isLoggedIn() ? HEADER_LINKS : []).filter(
        headerLink =>
          (headerLink.title !== 'Fondslisten' || sparte.features.value.mitFonds) &&
          (headerLink.title !== 'Berufelisten' || sparte.features.value.mitBerufen)
      )
    )
  );

  constructor() {
    this._promptUpdateService.updateApp$.pipe(take(1)).subscribe(() => {
      this._matDialog
        .open(UpdateAppModalComponent, { data: { appName: 'TI' }, disableClose: true })
        .afterClosed()
        .subscribe(() => {
          window.location.reload();
        });
    });

    this._customIconsService.init();
  }

  private selectSparte() {
    return this._state.selectedSparte$.pipe(
      filter(sparteId => sparteId != null && sparteId.trim() != ''),
      switchMap(sparteId => this._sparte.getSparte({ sparteId })),
      share()
    );
  }

  private selectProdukt() {
    return this._state.selectedProdukt$.pipe(
      distinctUntilChanged(),
      filter(produktId => produktId != null && produktId.trim() != ''),
      switchMap(produktId => this._produkt.getProdukt({ produktId }))
    );
  }
}
